<template>
  <div class="card card-custom">
    <div class="card-header d-flex align-items-center justify-content-between">
      <div class="card-title">
        <h3 class="card-label">{{ $t('BREADCRUMBS.CLIENT_REG') }}</h3>
      </div>
    </div>
    <div class="card-body">
      <v-form class="form">
        <div>
          <!-- <p>aaaaa</p> -->

          <v-select
            :items="clientType"
            v-model="selected_opt"
            @change="ddd(selected_opt)"
            item-text="name"
            dense
            item-value="value"
            :label="$t('INPUTS.CLIENT_TYPE')"
            outlined
          ></v-select>
        </div>
        <div class="d-flex">
          <div class="col-4 pl-0">
            <v-text-field
              :error-messages="nameErrors"
              v-model="username"
              :label="this.$t('INPUTS.LOGIN')"
              outlined
              type="text"
              dense
              class="my-input"
              required
              @input="$v.username.$touch()"
              @blur="$v.username.$touch()"
            ></v-text-field>
          </div>
          <div class="col-4">
            <v-text-field
              type="password"
              :error-messages="passErrors"
              v-model="password"
              :label="$t('REG_FORMS.PASSWORD')"
              autocomplete="on"
              outlined
              dense
              required
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            ></v-text-field>
          </div>

          <div class="col-4 pr-0">
            <v-text-field
              :error-messages="rePassErrors"
              type="password"
              autocomplete="on"
              v-model="rePassword"
              :label="$t('REG_FORMS.REPEAT_PASS')"
              outlined
              dense
              required
              @input="$v.rePassword.$touch()"
              @blur="$v.rePassword.$touch()"
            ></v-text-field>
          </div>
        </div>
        <div v-if="JI">
          <div
            style="
              border: 1px solid #333;
              border-radius: 4px;
              padding: 20px;
              margin-bottom: 20px;
            "
          >
            <h5 style="margin-bottom: 1.75rem">
              {{ $t('INPUTS.JI_INFO') }}
            </h5>
            <div>
              <v-text-field
                :error-messages="clientNameErrors"
                v-model="clientName"
                outlined
                :label="this.$t('INPUTS.CLIENT_NAME')"
                dense
                required
                @input="$v.clientName.$touch()"
                @blur="$v.clientName.$touch()"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <div class="col-6 pl-0">
                <v-select
                  :error-messages="clientJisStatus"
                  :items="returnClientStatus11"
                  v-model="status11"
                  item-text="status"
                  item-value="id"
                  dense
                  :label="this.$t('INPUTS.STATUS')"
                  outlined
                  @input="$v.status11.$touch()"
                  @blur="$v.status11.$touch()"
                ></v-select>
              </div>

              <div class="col-6 pr-0">
                <v-text-field
                  :error-messages="clientPassportNumber"
                  v-mask="'AA#######'"
                  v-model="passportNumber"
                  placeholder="AA1234567"
                  :label="this.$t('INPUTS.PASSPORT_NUMBER')"
                  required
                  @input="$v.passportNumber.$touch()"
                  @blur="$v.passportNumber.$touch()"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-combobox
                  v-model="oblast"
                  :error-messages="oblastErrors"
                  :items="returnOblast"
                  item-text="name"
                  item-value="id"
                  :label="this.$t('INPUTS.OBLAST')"
                  dense
                  outlined
                  required
                  @input="$v.oblast.$touch()"
                  @blur="$v.oblast.$touch()"
                ></v-combobox>
              </div>
              <div class="col-4">
                <v-combobox
                  v-model="region"
                  :error-messages="regionErrors"
                  :items="returnRegions"
                  item-text="name"
                  item-value="id"
                  :label="this.$t('INPUTS.REGION')"
                  dense
                  outlined
                  @input="$v.region.$touch()"
                  @blur="$v.region.$touch()"
                ></v-combobox>
              </div>
              <div class="col-4 pr-0">
                <v-text-field
                  v-model="adress"
                  :label="this.$t('INPUTS.ADDRESS')"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>
            <div>
              <v-textarea
                outlined
                v-model="comments"
                dense
                :label="this.$t('TABLE_HEADER.COMMENT')"
              ></v-textarea>
            </div>
          </div>
        </div>

        <div v-if="YU">
          <div
            style="
              border: 1px solid #333;
              border-radius: 4px;
              padding: 20px;
              margin-bottom: 20px;
            "
          >
            <h5 style="margin-bottom: 1.75rem">
              {{ $t('INPUTS.YU_INFO') }}
            </h5>
            <div>
              <v-text-field
                :error-messages="clientNameErrors"
                v-model="clientName"
                :label="$t('INPUTS.CLIENT_NAME')"
                dense
                outlined
                required
                @input="$v.clientName.$touch()"
                @blur="$v.clientName.$touch()"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-text-field
                  :error-messages="clientYurInn"
                  outlined
                  v-model="inn"
                  dense
                  v-mask="'### ### ###'"
                  :label="$t('TABLE_HEADER.INN')"
                  required
                  @input="$v.inn.$touch()"
                  @blur="$v.inn.$touch()"
                ></v-text-field>
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="oked"
                  :counter="5"
                  v-mask="'#####'"
                  :label="$t('TABLE_HEADER.OKED')"
                  dense
                  required
                  outlined
                ></v-text-field>
              </div>
              <div class="col-4 pr-0">
                <v-text-field
                  outlined
                  v-model="ndsNumber"
                  :label="$t('TABLE_HEADER.NDS')"
                  v-mask="'###############'"
                  required
                  :counter="15"
                  dense
                ></v-text-field>
              </div>
            </div>

            <h5 class="mb-5 text-dark">
              {{ $t('INPUTS.BANK_INFO') }}
            </h5>
            <div class="d-flex">
              <div class="col-6 pl-0">
                <v-autocomplete
                  :error-messages="mfoClient"
                  v-model="mfo"
                  :items="getMfo"
                  dense
                  item-text="full_name"
                  item-value="id"
                  :label="this.$t('INPUTS.MFO')"
                  outlined
                  @input="$v.mfo.$touch()"
                  @blur="$v.mfo.$touch()"
                  @keypress="isNumberKey"
                  v-debounce:200ms="getMfoDebounce"
                ></v-autocomplete>
              </div>
              <div class="col-6 pr-0">
                <v-text-field
                  v-model="account"
                  outlined
                  dense
                  :label="this.$t('INPUTS.BANK_NUMBER')"
                  v-mask="'#### #### #### #### ####'"
                  :error-messages="bankNumber"
                  @input="$v.account.$touch()"
                  @blur="$v.account.$touch()"
                ></v-text-field>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-combobox
                  v-model="oblast"
                  :items="returnOblast"
                  item-text="name"
                  item-value="id"
                  :label="this.$t('INPUTS.OBLAST')"
                  dense
                  outlined
                ></v-combobox>
              </div>
              <div class="col-4">
                <v-combobox
                  v-model="region"
                  :items="returnRegions"
                  item-text="name"
                  item-value="id"
                  :label="this.$t('INPUTS.REGION')"
                  dense
                  outlined
                ></v-combobox>
              </div>

              <div class="col-4 pr-0">
                <v-text-field
                  outlined
                  v-model="yuAdress"
                  :label="this.$t('INPUTS.STREET')"
                  required
                  dense
                ></v-text-field>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-text-field
                  v-model="adress"
                  outlined
                  :label="this.$t('INPUTS.DOP_ADDRESS')"
                  required
                  dense
                ></v-text-field>
              </div>
              <div class="col-4">
                <v-select
                  :items="returnOwnership"
                  v-model="typeOfProperty"
                  item-text="name"
                  item-value="id"
                  outlined
                  :label="this.$t('INPUTS.CLIENT_FORM')"
                  dense
                ></v-select>
              </div>

              <div class="col-4 pr-0">
                <v-select
                  :error-messages="clientYurStatus"
                  :items="returnClientStatus22"
                  v-model="status22"
                  item-text="status"
                  item-value="id"
                  :label="this.$t('INPUTS.STATUS')"
                  dense
                  outlined
                  @input="$v.status22.$touch()"
                  @blur="$v.status22.$touch()"
                ></v-select>
              </div>
            </div>
            <div>
              <v-textarea
                outlined
                dense
                v-model="comments"
                :label="this.$t('TABLE_HEADER.COMMENT')"
              ></v-textarea>
            </div>
          </div>
          <div
            style="border: 1px solid #333; border-radius: 4px; padding: 20px"
          >
            <h5 class="mb-10 text-dark">
              {{ $t('INPUTS.CONTRACTS_INFO') }}
            </h5>
            <h5 class="mb-5 text-dark">{{ $t('INPUTS.DIRECTOR') }}:</h5>
            <div>
              <v-text-field
                :error-messages="dirNameErrors"
                v-model="dirName"
                :label="this.$t('INPUTS.FIO')"
                dense
                outlined
                required
                @input="$v.dirName.$touch()"
                @blur="$v.dirName.$touch()"
              ></v-text-field>
            </div>
            <div>
              <label>{{ $t('INPUTS.PHONE_NUMBER') }}</label>
              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-text-field
                    v-model="directorPhoneNumber"
                    outlined
                    dense
                    append-icon="phone"
                    placeholder="(XX) XXX XX XX"
                    required
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>
                <div class="col-6 pr-0">
                  <v-text-field
                    v-model="directorPhoneNumber2"
                    outlined
                    placeholder="(XX) XXX XX XX"
                    append-icon="phone"
                    required
                    dense
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="separator separator-dashed my-5"></div>

            <h5 class="mb-5 text-dark">{{ $t('INPUTS.RESPONSIBLE_STAFF') }}</h5>
            <div class="d-flex">
              <div class="col-6 pl-0">
                <v-text-field
                  v-model="stuffName"
                  dense
                  outlined
                  :label="this.$t('INPUTS.FIO')"
                ></v-text-field>
              </div>
              <div class="col-6 pr-0">
                <v-select
                  :items="returnPositions"
                  v-model="stuffPosition"
                  item-text="name"
                  item-value="id"
                  :label="this.$t('INPUTS.POSITION')"
                  dense
                  outlined
                ></v-select>
              </div>
            </div>
            <div>
              <label>{{ $t('INPUTS.PHONE_NUMBER') }}</label>

              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-text-field
                    v-model="stuffPhoneNumber"
                    outlined
                    append-icon="phone"
                    placeholder="(XX) XXX XX XX"
                    dense
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>
                <div class="col-6 pr-0">
                  <v-text-field
                    v-model="stuffPhoneNumber2"
                    outlined
                    append-icon="phone"
                    dense
                    placeholder="(XX) XXX XX XX"
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <div class="separator separator-dashed my-5"></div>
        </div>

        <div class="d-flex justify-content-between pt-10">
          <div class="card-toolbar" :disabled="clientRegistering">
            <el-button
              v-on:click="submit"
              :disabled="clientRegistering"
              class="btn btn-primary font-weight-bolder"
            >
              <span class="svg-icon svg-icon-white"
                ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg-->
                <svg
                  v-if="!clientRegistering"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>

                <i v-else class="el-icon-loading"></i>
                <!--end::Svg Icon--></span
              >

              {{ $t('FORM.SUBMIT') }}</el-button
            >
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
// import { required, numeric, minLength, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from 'vuelidate'
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { isNumberKey } from '@/utils'
import { mapActions } from 'vuex'

export default {
  mixins: [validationMixin],
  name: 'client_red',
  data() {
    return {
      clientRegistering: false,
      code: '998',
      modelfield: '',
      directorPhoneNumber: '',
      directorPhoneNumber2: '',
      username: '',
      password: '',
      rePassword: '',
      stuffName: '',
      stuffPosition: '',
      clientName: '',
      stuffPhoneNumber: '',
      stuffPhoneNumber2: '',
      mfo: '',
      oblast: '',
      region: '',
      status11: '',
      status22: '',
      yuAdress: '',
      typeOfProperty: '',
      adress: '',
      inn: '',
      dirName: '',
      ndsNumber: '',
      oked: '',
      comments: '',
      account: '',
      bankAccountList: [],
      bankDetailsList: [],
      clientType: [
        { name: this.$t('INPUTS.CLIENT_TYPE_JI'), value: 'JI' },
        { name: this.$t('INPUTS.CLIENT_TYPE_YU'), value: 'YU' }
      ],
      selected_opt: '',
      passportNumber: '',
      JI: false,
      YU: false
    }
  },
  validations() {
    if (this.JI == true) {
      return {
        status11: {
          required
        },
        account: {
          // numeric
        },
        username: {
          required
        },
        password: {
          required,
          minLength: minLength(8)
        },
        rePassword: {
          sameAsPassword: sameAs('password')
        },
        clientName: {
          required
        },
        oblast: {
          required
        },
        region: {
          required
        },
        passportNumber: {
          required
        }
      }
    } else {
      return {
        status22: {
          required
        },
        account: {
          required,
          minLength: minLength(24)
        },
        username: {
          required
        },
        dirName: {
          required
        },
        password: {
          required,
          minLength: minLength(8)
        },
        rePassword: {
          sameAsPassword: sameAs('password')
        },
        clientName: {
          required
        },
        inn: {
          required
        },
        oblast: {
          required
        },
        region: {
          required
        },
        mfo: {
          required
        }
      }
    }
  },

  beforeCreate() {
    // this.$store.dispatch("getPositions");

    this.$store.dispatch('getPositions')
    this.$store.dispatch('getAllMfoBank')
    this.$store.dispatch('getClientStatus')
    this.$store.dispatch('getRegions')
    this.$store.dispatch('getOwnership')
    this.$store.dispatch('getAllGroups')
  },
  watch: {
    clientName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.clientName = str
    },
    username: function (val) {
      this.username = val.toLowerCase()
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.username))
        this.username = this.username.replace(/\s/g, '')
    },
    dirName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.dirName = str
    },
    stuffName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.stuffName = str
    }
  },
  computed: {
    allGroups() {
      return this.$store.state.requests.allGroups.find(
        (x) => x.name === 'client'
      )
    },
    getMfo() {
      return this.$store.state.requests.allMfoBank
    },
    nameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    dirNameErrors() {
      const errors = []
      if (!this.$v.dirName.$dirty) return errors
      !this.$v.dirName.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientNameErrors() {
      const errors = []
      if (!this.$v.clientName.$dirty) return errors
      !this.$v.clientName.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },

    clientYurInn() {
      const errors = []
      if (!this.$v.inn.$dirty) return errors
      !this.$v.inn.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mfoClient() {
      const errors = []
      if (!this.$v.mfo.$dirty) return errors
      !this.$v.mfo.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    bankNumber() {
      const errors = []
      if (!this.$v.account.$dirty) return errors
      !this.$v.account.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")

      if (
        !this.$v.account.minLength ||
        this.account.replace(/( )/g, ' ').length < 20
      ) {
        errors.push("Bank hisob raqami 20 ta raqamdam kam bo'lmasligi kerak")
      }

      return errors
    },
    oblastErrors() {
      const errors = []
      if (!this.$v.oblast.$dirty) return errors
      !this.$v.oblast.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientPassportNumber() {
      const errors = []
      if (!this.$v.passportNumber.$dirty) return errors
      !this.$v.passportNumber.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientJisStatus() {
      const errors = []
      if (!this.$v.status11.$dirty) return errors
      !this.$v.status11.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientYurStatus() {
      const errors = []
      if (!this.$v.status22.$dirty) return errors
      !this.$v.status22.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    regionErrors() {
      const errors = []
      if (!this.$v.region.$dirty) return errors
      !this.$v.region.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    passErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength &&
        errors.push("Password 8 tadan ko'p bo'lishi kerak characters long")
      !this.$v.password.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    rePassErrors() {
      const errors = []
      if (!this.$v.rePassword.$dirty) return errors
      !this.$v.rePassword.sameAsPassword &&
        errors.push('Parol most bolishi kerak')
      return errors
    },
    returnPositions() {
      return this.$store.state.requests.positions.results
    },
    returnOwnership() {
      return this.$store.state.requests.ownership
    },
    returnClientStatus11() {
      const data = this.$store.state.requests.clientStatus
      return data
    },
    returnClientStatus22() {
      const data22 = this.$store.state.requests.clientStatus
      return data22
    },
    returnOblast() {
      return this.$store.state.requests.regions
    },
    returnRegions() {
      let data = ''
      if (this.oblast !== null) {
        this.$store.state.requests.regions.forEach((item) => {
          if (item.id == this.oblast.id) {
            data = item.oblast_region
          }
        })
      }
      return data
    }
  },
  methods: {
    isNumberKey,

    ...mapActions(['getMfoBankByNumber']),

    getMfoDebounce(val) {
      this.getMfoBankByNumber(val)
    },

    ddd(value) {
      if (value == 'JI') {
        this.JI = true
        this.YU = false
      } else if (value == 'YU') {
        this.JI = false
        this.YU = true
      }
    },
    setUsername(value) {
      this.username = value
      this.$v.username.$touch()
    },
    setBankNum(value) {
      this.account = value
      this.$v.account.$touch()
    },
    submit: function (e) {
      e.preventDefault()
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        this.clientRegistering = true
        const data = {
          // profile_type: "client",
          user_data: {
            username: this.username.toLowerCase(),
            password: this.password,
            groups: this.allGroups.id
          },

          ownership: this.typeOfProperty,
          adress_oblast: this.oblast.id,
          adress_region: this.region.id,
          code_nds: this.ndsNumber.split(' ').join(''),
          passport_number: this.passportNumber,
          juridical_type: this.selected_opt,
          full_name: this.clientName,
          inn: this.inn.split(' ').join(''),
          oked: this.oked,
          yur_address: this.yuAdress,

          comments: this.comments,
          pochta_address: this.adress,

          // director_phone1: this.code + " " + this.directorPhoneNumber,
          // director_phone2: this.code + " " + this.directorPhoneNumber2,

          // contact_personal_phone1: this.code + " " + this.stuffPhoneNumber,
          // contact_personal_phone2: this.code + " " + this.stuffPhoneNumber2,
          bank_code1: parseInt(this.mfo),
          bank_account1: this.account.split(' ').join('')
        }
        // if (
        //   this.directorPhoneNumber == "" ||
        //   this.directorPhoneNumber2 == "" ||
        //   this.stuffPhoneNumber == "" ||
        //   this.stuffPhoneNumber == ""
        // ) {
        //   this.code = "";
        // }
        if (this.directorPhoneNumber !== '') {
          data.director_phone1 = this.code + ' ' + this.directorPhoneNumber
        }
        if (this.directorPhoneNumber2 !== '') {
          data.director_phone2 = this.code + ' ' + this.directorPhoneNumber2
        }
        if (this.stuffPhoneNumber !== '') {
          data.contact_personal_phone1 = this.code + ' ' + this.stuffPhoneNumber
        }
        if (this.stuffPhoneNumber2 !== '') {
          data.contact_personal_phone2 =
            this.code + ' ' + this.stuffPhoneNumber2
        }
        if (this.JI == true) {
          delete data.director_name
          delete data.contact_personal_position
          delete data.contact_personal_name
          data.status = this.status11
        } else if (this.YU == true) {
          data.director_name = this.dirName
          data.contact_personal_position = this.stuffPosition
          data.contact_personal_name = this.stuffName
          data.status = this.status22
        }

        console.log(data)
        this.$store
          .dispatch('createClient', data)
          .then(() => {
            console.log('done')
            this.clientRegistering = false
          })
          .catch((err) => {
            this.clientRegistering = false
            console.error(err)
          })
        this.$v.$reset()
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('BREADCRUMBS.SOLD') },
      { title: this.$t('BREADCRUMBS.CLIENT_REG') }
    ])
  }
}
</script>
<style scoped>
.my-input /deep/ input {
  text-transform: lowercase !important;
}
.error {
  color: red;
  display: none;
}
.form-group--error {
  display: block;
}
.v-application .error {
  background-color: inherit !important;
}
</style>
